body {
  // width: 90% auto;
  background: rgb(202, 235, 255);
  background: linear-gradient(180deg, rgba(202, 235, 255, 1) 0%, rgba(228, 245, 255, 1) 100%);
  background-size: contain;
  min-height: 100vh;
  height: auto;
  margin: 0;

  font-family: 'Somatic';
}


img {
  max-width: 100%;
  height: auto;
}


#footer {
  color: #FFA130;
  background-color: transparent;
  background-image: url('../src/assets/img/footer.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  /* margin: 5vw auto; */
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  transition: ease-out .7s;
  padding-top: 4vw;

  .imgProfile {
    position: relative;

    img {
      width: 10vw;
      height: 10vw;
      background-color: #B6E2FE;
      border-radius: 100px;
    }

    .editimg {
      position: absolute;
      top: 0;
      right: 35%;
      background-color: #fff;
      background-image: url('../src/assets/img/Group 1364.png');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      height: 65px;
      -moz-border-radius: 75px;
      -webkit-border-radius: 75px;
      width: 30px;
    }

  }

  .bodyEdit {
    padding: 0 2vw;
    z-index: 99;


    .formProfile {
      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;



        .formdata {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: flex-start;
          align-content: flex-start;

        }

        .rowinner2 {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          margin-top: 2vw;
          font-size: 19px;

          .titleRow {
            color: #65B5FF;
            text-align: left;
            margin-top: 25px;
            margin-right: 2vw;
          }

          //#7B67DB
          .gendertype {
            cursor: pointer;

            .textgender {
              color: #7B67DB;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
              align-content: center;
              transition: 0.5s all;
            }
          }

          img {
            margin-right: 1vw;
          }
        }

        .rowinner {

          font-size: 19px;

          .titleRow {
            color: #65B5FF;
            text-align: left;
            margin-top: 25px;
            margin-bottom: 10px;

          }

          .inputDate {
            margin-right: 5px;
            min-width: 6.5vw;
            width: auto;
            background-color: #fff;
          }

          .inputtext {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .title {
    font-size: 2.0vw;
    margin-top: 2vw;
    transition: 1s all;
    margin-bottom: 2vw;


    .avatar {
      margin-top: 2vw;
      width: 60vw;

      .titleAvatar {
        font-size: 18px;
        color: #4F5472;
      }

      .listAvatar {
        background-color: #fff;
        width: 100%;
        min-height: 10vw;
        height: auto;
        margin: 1vw 0;
        border-radius: 25px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.11);

        img {
          cursor: pointer;
          width: 8vw;
          // background-color: #13bba4;
          border-radius: 50%;
          margin: 1vw 1vw;
        }
      }
    }

    .errorMsg {
      background-color: #FD7777;
      color: #fff;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      text-align: center;
      width: auto;
      padding: .61vw 1vw;
      border-radius: 15px;
      margin: auto;
      text-align: left;

      svg {
        margin-right: 1vw;
      }
    }

    .img {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      background-color: #B6E2FE;
      width: 10vw;
      height: 10vw;
      text-align: center;
      margin: auto;
      border-radius: 100px;
    }

    .textTitle {
      margin-top: 5%;
      text-align: center;
      color: #4F5472;
      font-family: 'Somatic';
      margin-bottom: 1vw;
    }



    .inputPhoneV {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      margin: 2vw 0;

      input {

        font-family: 'Somatic';
        border: #C2D2F2 2px solid;
        border-radius: 100px;
        font-size: 19px;
        text-align: center;

      }
    }

    .input {
      text-align: center;
      margin: auto;

      position: relative;

      input {
        width: 88%;
        height: 25px;
        padding: 10px;
        font-family: 'Somatic';
        border: #C2D2F2 2px solid;
        border-radius: 25px;
        font-size: 15px;
        margin-top: 10px;
        padding-left: 8%;

      }

      svg {
        position: absolute;
        left: 3%;
        bottom: 22%;
      }

    }

    .inputPhone {
      margin: auto;
      margin-top: 10px;

      input {
        border-radius: 25px;
        width: 100%;
        height: 50px;
        font-size: 15px;
        border: #C2D2F2 2px solid;
        font-family: 'Somatic';
      }

      .react-tel-input {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        text-align: left;

      }

      .flag-dropdown {
        // border-radius: 10px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-left: #C2D2F2 2px solid;
        border-top: #C2D2F2 2px solid;
        border-bottom: #C2D2F2 2px solid;

      }

    }

    .textunderphone {
      padding-top: 10px;
      font-size: 14px;
      color: #6A91AC;
      text-align: center;

      a {
        color: #65B5FF;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .forgetpassword {
      padding-top: 10px;
      text-align: right;
      font-size: 15px;
      width: 99%;

      a {
        color: #65B5FF;
      }
    }

    .btns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      margin-top: 1vw;
      font-family: 'Somatic';

      .btn1 {
        background: rgb(255, 206, 0);
        background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 10px 0;
        border-radius: 50px;
        width: 25vw;
        box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        text-align: center;
        text-decoration: none;
        margin-top: 1vw;
        font-family: 'Somatic';
      }

      .disbtn {
        background: rgba($color: #999, $alpha: 0.9);
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 10px 0;
        border-radius: 50px;
        width: 25vw;
        // box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: no-drop;
        outline: none;
        text-align: center;
        text-align: center;
        text-decoration: none;
        margin-top: 2vw;
        font-family: 'Somatic';
      }

    }

    .otherlogin {
      p {
        font-size: 15px;
        text-align: center;
        color: #6A91AC;
        margin-top: 1vw;
      }

      .socailMedia {
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
          cursor: pointer;
          margin: 0 1vw;
        }
      }

      .signup {
        font-size: 16px;
        text-align: center;
        color: #6A91AC;
        margin-top: 1vw;

        a {
          color: #65B5FF;
        }
      }
    }

    .btnsFree {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      margin-top: 1vw;

      button {
        background-color: transparent;
        border: none;
        text-align: center;
        font-size: 18px;
        font-family: 'Somatic';
        color: #FFA130;
        cursor: pointer;
        outline: none;
      }
    }


  }

  .footer {
    text-align: center;
    color: #4F5472;
    font-size: 15px;
    position: -ms-page;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;

  }

  input:focus {
    outline: none !important;
  }

  .stage {
    margin-left: 1vh;
    position: relative;

    img {}

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 20%;
    }

    #cloud2 {
      position: fixed;
      left: 20%;
      top: 30%;
    }

    #backbtn {
      cursor: pointer;
      position: fixed;
      left: 12%;
      top: 2%;
    }

    #backbtnImg {
      width: 7vw;
      cursor: pointer;
      position: fixed;
      left: 3%;
      top: 2%;
    }


  }

  .Interests {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 20vw;
    z-index: 99;
    position: relative;

    .itemInterest {
      margin: 0.5vw .5vw;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      position: relative;
      cursor: pointer;
      width: 10.5vw;
      height: 10.5vw;

      &::before {
        content: "";
        background: rgba($color: #7C68DC, $alpha: 0.0);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        margin: 0;
        padding: 0;
        border-radius: 20px;
        transition: 0.5s all;

      }

      img {
        width: 7.5vw;
        height: 6;
        object-fit: cover;
        opacity: 0.8;
        padding: .5vw 1.5vw;

      }

      .titleInt {
        color: #4F5472;
        font-size: 1vw;
        padding-bottom: 1vw;
      }

    }

    .itemInterestActive {
      margin: 0.5vw .5vw;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        background: rgba($color: #7C68DC, $alpha: 0.59);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        margin: 0;
        padding: 0;
        border-radius: 20px;
        transition: 0.5s all;

      }

      img {
        width: 7.5vw;
        height: 6;
        object-fit: cover;
        opacity: 0.8;
        padding: .5vw 1.5vw;

      }

      .titleInt {
        color: #4F5472;
        font-size: 1vw;
        padding-bottom: 1vw;
      }

    }

    .itemInterest::after {
      content: "\2714\0020";
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      z-index: 99999;
      color: #7C68DC;
      font-size: 3vw;
      font-weight: bolder;
      background-color: #fff;
      width: 5vw;
      height: 5vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 50%;
      transition: 0.5s all;
      visibility: hidden;
      opacity: 0;
      transition: 1s all;
    }

    .itemInterestActive::after {
      content: "\2714\0020";
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      z-index: 99999;
      color: #7C68DC;
      font-size: 3vw;
      font-weight: bolder;
      background-color: #fff;
      width: 5vw;
      height: 5vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 50%;
      visibility: visible;
      opacity: 1;
      transition: 1s all;
    }
  }

}

#Episode {

  .header {
    background-color: #634FC3;
    padding: 0 3vw;
    margin: 0;
    height: 7vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    .titleSeason {
      color: #fff;
      font-size: 36px;
      width: 70%;
    }

    img {
      width: 7vw;
      cursor: pointer;
      left: 2%;
      top: 1%;

    }

    #backbtn {
      cursor: pointer;
      left: 10%;
      top: 0%;
      width: 6vw;

    }
  }



  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 2vw 2vw;
    z-index: 999;

    .coverSeason {
      width: 93%;
      height: 25vh;
      margin: auto;

      img {
        width: 100%;
        height: 25vh;
        object-fit: fill;
        position: relative;
        border-radius: 15px;
      }
    }

    .predcom {
      padding-top: 1vw;
      font-size: 25px;
      text-align: left;
      margin-left: 4%;
      color: #4F5472;
    }

    .seasons {
      padding-bottom: 2vw;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding-left: 2.5vw;

      .item {
        position: relative;
        margin: 1vw 1vw;
        cursor: pointer;
        outline: none;

        .titleitem {
          font-size: 18px;
          text-align: left;
          color: #fff;
          position: absolute;
          bottom: 3%;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
        }

        .mainImg {

          width: 16vw;
          height: 10vw;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        #more {
          position: absolute;
          right: 5%;
          top: 5%;
          cursor: pointer;
          padding: 0 10px;
        }

        #info {
          position: absolute;
          right: 3%;
          bottom: 5%;
          cursor: pointer;
        }

        .gotocontent {
          position: absolute;
          left: 0;
          bottom: 0%;
          width: 85%;
          height: 100%;
          background-color: transparent;
          z-index: 99;
        }

        &::before {
          content: "";
          position: absolute;
          min-width: 100%;
          min-height: 99%;
          background: rgba(0, 0, 0, 0.41);
          -webkit-box-shadow: inset 2px 180px 160px 16px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: inset 2px 180px 160px 16px rgba(0, 0, 0, 0.25);
          box-shadow: inset 2px 180px 120px 16px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
        }
      }
    }
  }
}

#season {
  .header {
    background-color: #634FC3;
    padding: 0 3vw;
    margin: 0;
    height: 7vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .titleSeason {
      color: #fff;
      font-size: 36px;
    }

    img {
      width: 7vw;
      cursor: pointer;
      position: absolute;
      left: 2%;
      top: 2%;

    }

    #backbtn {
      cursor: pointer;
      position: absolute;
      left: 10%;
      top: 2%;
      width: 6vw;
    }
  }



  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 2vw 2vw;
    z-index: 99;

    .coverSeason {
      width: 93%;
      height: 25vh;
      margin: auto;

      img {
        width: 100%;
        height: 25vh;
        object-fit: cover;
        position: relative;
        border-radius: 15px;
      }
    }

    .countitem {
      padding-top: 2vw;
      font-size: 32px;
      text-align: left;
      margin-left: 4%;
      color: #7B67DB;
    }

    .seasons {
      padding-bottom: 2vw;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      padding-left: 2.5vw;

      .titleitem {
        font-size: 18px;
        text-align: left;
        margin-left: 1vw;
        margin-top: -0.5vw;
        color: #4F5472;
        cursor: pointer;
      }

      .item {
        position: relative;
        margin: 1vw 1vw;
        cursor: pointer;
        outline: none;

        .mainImg {

          width: 16vw;
          height: 10vw;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        #more {
          position: absolute;
          right: 5%;
          top: 5%;
          cursor: pointer;
          padding: 0 10px;
        }

        #info {
          position: absolute;
          right: 3%;
          bottom: 5%;
          cursor: pointer;
        }

        .gotocontent {
          position: absolute;
          left: 0;
          bottom: 0%;
          width: 85%;
          height: 100%;
          background-color: transparent;
          z-index: 99;
        }

        &::before {
          content: "";
          position: absolute;
          min-width: 100%;
          min-height: 99%;
          background: rgba(0, 0, 0, 0.01);
          -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
          box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
        }
      }
    }
  }
}

#Parents {


  .header {
    padding: 0 3vw;
    margin: 0;
    height: 9vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;


  }

  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 2vw 2vw;
    z-index: 99;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .title {
      font-size: 2.4vw;
      width: 40vw;

      .img {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        background-color: #B6E2FE;
        width: 10vw;
        height: 10vw;
        text-align: center;
        margin: auto;
        border-radius: 100px;
      }

      .textTitle {
        margin-top: 3%;
        text-align: center;
        color: #4F5472;
        font-family: 'Somatic';
      }

      .textTitle2 {
        margin-top: 3%;
        text-align: center;
        color: #4F5472;
        font-family: 'Somatic';
        font-size: 1vw;
      }

      .input {
        text-align: center;
        margin: auto;
        background-color: #fff;
        padding: 2vw;
        width: 60%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 2vw;
        border-radius: 25px;
        box-shadow: 0 2px 8px rgba(186, 188, 240, 0.33);

        .qus {
          margin-right: 1vw;
          color: #7C68DC;
        }

        input {
          width: 20%;
          height: 1.5vw;
          padding: 10px;
          height: 3vw;
          font-family: 'Somatic';
          border: #C2D2F2 3px solid;
          border-radius: 15px;
          font-size: 2vw;
          color: #7C68DC;
          text-align: center;
          outline: none;
        }
      }

      .inputPhone {
        margin: auto;
        margin-top: 10px;
        width: 63%;

        input {
          border-radius: 15px;
          width: 100%;
          height: 50px;
          font-size: 15px;
          border: #C2D2F2 3px solid;
          font-family: 'Somatic';
        }

        .react-tel-input {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          text-align: left;

        }

        .flag-dropdown {
          // border-radius: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-left: #C2D2F2 3px solid;
          border-top: #C2D2F2 3px solid;
          border-bottom: #C2D2F2 3px solid;

        }

      }

      .textunderphone {
        padding-top: 10px;
        font-size: 14px;
        color: #6A91AC;
        text-align: center;

        a {
          color: #65B5FF;
        }
      }


      .btns {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        margin-top: 1vw;
        font-family: 'Somatic';

        .btn1 {
          background: rgb(255, 206, 0);
          background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
          color: #fff;
          border: none;
          font-size: 18px;
          padding: 10px 0;
          border-radius: 50px;
          width: 40%;
          box-shadow: 1px 3px 9px #BABCF0;
          font-family: 'Somatic';
          cursor: pointer;
          outline: none;
          text-align: center;
          text-align: center;
          text-decoration: none;
          margin-top: 2vw;
          font-family: 'Somatic';
        }


      }

      .otherlogin {
        p {
          font-size: 15px;
          text-align: center;
          color: #6A91AC;
          margin-top: 1vw;
        }

        .socailMedia {
          text-align: center;
        }

        .signup {
          font-size: 16px;
          text-align: center;
          color: #6A91AC;
          margin-top: -1.5vw;

          a {
            color: #65B5FF;
          }
        }
      }

      .btnsFree {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        margin-top: 1vw;

        button {
          background-color: transparent;
          border: none;
          text-align: center;
          font-size: 18px;
          font-family: 'Somatic';
          color: #FFA130;
          cursor: pointer;
          outline: none;
        }
      }
    }

  }

}

#privacyPolicy {
  margin: 2vw 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;

  .privacyPolicyBody {
    background-color: #fff;
    width: 60%;
    margin: auto;
    padding: 2vw;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgba(123, 103, 219, 0.21);
    font-size: 18px;
    text-align: left;
    color: #4F5472;
  }
}

#Notification {
  margin: 2vw 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  .severityinfo {
    border: 2.5px rgba(123, 103, 218, .6) solid;
    border-radius: 10px;
    font-size: 1vw;
    position: relative;

    .dateR {
      position: absolute;
      right: 2%;
      top: 30%;
      font-size: 12px;
      font-family: 'Somatic';
      color: #9888E3;
      opacity: 0.8;
    }
  }
}

#Language {
  margin: 2vw 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  .langlsit {
    .lang {
      background-color: rgba(123, 103, 218, .2);
      width: 15vw;
      margin: 1vw 0;
      text-align: left;
      padding: 1vw 1.2vw;
      color: rgba(123, 103, 218, .8);
      border-radius: 25px;
      font-size: 20px;
      border: 2px #fff solid;
      cursor: pointer;
      position: relative;
      transition: 1.5 all;

      .Check {
        position: absolute;
        right: 5%;
        top: 20%;
        width: 2.2vw;
        height: 2.2vw;
        background-color: rgba(123, 103, 218, .3);
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 50px;
      }
    }

    .langActive {
      background-color: #fff;
      width: 15vw;
      margin: 1vw 0;
      text-align: left;
      padding: 1vw 1.2vw;
      color: rgba(123, 103, 218, .8);
      border-radius: 25px;
      font-size: 20px;
      border: 2px rgba(123, 103, 218, .6) solid;
      cursor: pointer;
      position: relative;
      transition: 1.5 all;

      .Check {
        position: absolute;
        right: 5%;
        top: 20%;
        width: 2.2vw;
        height: 2.2vw;
        background-color: rgba(123, 103, 218, 1.3);
        border: 2px solid rgba(255, 255, 255, 0.8);
        border-radius: 50px;
        content: "✔";

      }

      :after {
        content: "\2714";
        font-size: 1.6vw;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        color: #fff;
      }
    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin-top: 1vw;
    font-family: 'Somatic';

    .btn1 {
      background: rgb(255, 206, 0);
      background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
      color: #fff;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 50px;
      width: 10vw;
      box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      text-align: center;
      text-decoration: none;
      font-family: 'Somatic';
    }


  }
}

#Subscription {
  margin: 2vw 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  .SubscriptionCurrent {
    font-size: 1.5vw;
    color: #4F5472;
  }

  .planuser {
    background-color: #fff;
    // width: 25vw;
    min-height: 5.5vw;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1.2vw 2vw;
    position: relative;
    margin-top: 2vw;
    border-radius: 25px;
    box-shadow: 0 2px 8px rgba(123, 103, 218, .2);

    img {
      padding: 0;
      margin: 0;
      width: 8vw;
    }


    .detName {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      padding-left: 1.2vw;

      .planName {
        color: #535875;
        font-size: 1.8vw;
        margin: 5px 0;
      }

      .planPrice {
        color: #6A91AC;
        font-size: 1.5vw;
        font-family: 'Somatic';
        padding-bottom: 5px;
      }

      .planRenew {
        color: #4F5472;

        a {
          padding-right: 10px;
          color: #99B4C6;
        }
      }

    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin-top: 1vw;
    font-family: 'Somatic';

    .btn1 {
      background: rgb(255, 206, 0);
      background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
      color: #fff;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 50px;
      width: 15vw;
      box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      text-align: center;
      text-decoration: none;
    }

    .btn2 {
      background: rgb(146, 145, 254);
      background: linear-gradient(180deg, rgba(146, 145, 254, 1) 0%, rgba(132, 133, 253, 1) 100%);
      color: #fff;
      border: none;
      font-size: 18px;
      width: 100%;
      padding: 10px 0;
      border-radius: 50px;
      width: 10vw;
      box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      outline: none;
      text-align: center;
      text-decoration: none;
    }
  }
}


#Profiles {

  margin: 4vw 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;

  .editimg {
    position: absolute;
    top: -10%;
    right: 5%;
    background-color: #fff;
    background-image: url('../src/assets/img/Group 1874.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vw;
    cursor: pointer;
    -moz-border-radius: 75px;
    -webkit-border-radius: 75px;
    height: 3.5vw;
    height: 3.5vw;
  }

  .profile {
    margin: 0 1vw;

    .nameProfile {
      color: #4F5472;
      font-size: 20px;
      margin-top: 5px;
    }

    .img {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      background-color: #fff;
      width: 8vw;
      height: 8vw;
      text-align: center;
      margin: auto;
      border-radius: 100px;
      color: #7B67DB;
      font-size: 3vw;
      font-family: 'Somatic';
      font-weight: bold;
      cursor: pointer;
    }

    .img3 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      background-color: #B6E2FE;
      width: 8vw;
      height: 8vw;
      text-align: center;
      margin: auto;
      border-radius: 100px;
      color: #7B67DB;
      font-size: 3vw;
      font-family: 'Somatic';
      font-weight: bold;
      cursor: pointer;
      border: #7B67DB solid 6px;
    }

    .img2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      background-color: #B6E2FE;
      width: 8vw;
      height: 8vw;
      text-align: center;
      margin: auto;
      border-radius: 100px;
      color: #7B67DB;
      font-size: 3vw;
      font-family: 'Somatic';
      font-weight: bold;
      cursor: pointer;
    }

  }

}

.Logoutstyle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;

  .textlogout {

    color: #FF5B7C;
    font-size: 20px;
    margin-left: .5vw;
  }
}

#EditProfile {
  .header {
    background-color: #634FC3;
    padding: 0 3vw;
    margin: 0;
    height: 9vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    .btn1 {
      background: rgb(255, 206, 0);
      background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
      color: #fff;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 50px;
      width: 8vw;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      text-align: center;
      text-decoration: none;
      font-family: 'Somatic';
    }

    .btn2 {
      // background: rgb(146, 145, 254);
      // background: linear-gradient(180deg, rgba(146, 145, 254, 1) 0%, rgba(132, 133, 253, 1) 100%);
      color: #FFA130;
      border: none;
      font-size: 18px;
      width: 100%;
      padding: 10px 0;
      border-radius: 50px;
      width: 8vw;
      // box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      outline: none;
      text-align: center;
      text-decoration: none;
      border: 2px solid #FFA130;
      cursor: pointer;
      font-family: 'Somatic';
    }

    .leftSec {

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;




    }

    .centerSec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      .settingsTitle {
        color: #fff;
        font-size: 2.1vw;
      }



    }

    .rightSec {

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;

    }
  }



  .bodyEdit2 {

    padding: 2vw 2vw;

    .History {
      width: 100%;

      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        width: 40%;
        background-color: #fff;
        margin: 2vw auto;
        border-radius: 25px;
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);

        position: relative;

        .leftSec {
          border-right: 1px solid #C2D2F2;
          height: 6vw;
          padding: 2vw;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          font-size: 20px;
          color: #505573;

          svg {
            padding-right: 1vw;
          }
        }

        .ScreenTime {
          padding: 2vw;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          font-size: 20px;
          color: #505573;

          .timer {
            padding-left: 2vw;
            color: #65DAA3;
            font-size: 22px;
          }

          .Delete {
            position: absolute;
            right: 2%;
            cursor: pointer;
          }

          svg {
            padding-right: 1vw;
          }
        }

        .rightSec {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          text-align: left;
          width: 100%;

          .rowSec {
            // padding: 1vw 1vw; 
            color: #505573;
            font-size: 18px;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            height: 5vw;
            cursor: pointer;

            svg {
              padding-right: 1vw;
            }

            .titleSec {
              padding-left: 1vw;
            }
          }

          .rowSec:first-child {
            border-bottom: 1px solid #C2D2F2;
          }
        }
      }
    }
  }

  .bodyEdit {
    padding: 2vw 2vw;
    z-index: 99;
    border-bottom: 2px solid #C2D2F2;

    .titleEdit {
      padding: 2vw 2vw;
      font-size: 25px;
      color: #505573;
    }

    .formProfile {
      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        align-content: center;

        .imgProfile {
          position: relative;

          img {
            width: 8vw;
            height: 8vw;
            background-color: rgba(99, 79, 195, .2);
            border-radius: 100px;
          }

          .editimg {
            position: absolute;
            top: 0;
            right: 0;
            width: 2vw;
            height: 3.3vw;
            background-color: #fff;
            border-radius: 100px;
            background-image: url('../src/assets/img/Group 1874.png');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
          }

          .btnEditInterests {
            color: #FFA130;
            border: #FFA130 solid 2px;
            border-radius: 25px;
            padding: 8px;
            font-weight: normal;
            margin-top: 0.5vw;
            cursor: pointer;
          }
        }

        .formdata {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: flex-start;
          margin-left: 4vw;

        }

        .rowinner2 {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          margin-top: 2vw;

          .titleRow {
            color: #505573;
            text-align: left;
            margin-top: 25px;
            margin-right: 2vw;
          }

          //#7B67DB
          .gendertype {
            cursor: pointer;

            .textgender {
              color: #7B67DB;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              align-content: center;
              transition: 0.5s all;
            }
          }

          img {
            margin-right: 1vw;
            width: 6vw;
          }
        }

        .rowinner {
          .titleRow {
            color: #505573;
            text-align: left;
            margin-top: 25px;
            margin-bottom: 10px;
          }

          .inputDate {
            margin-right: 5px;
            min-width: 6vw;
            width: auto;
            background-color: #fff;
          }

          .inputtext {
            margin-right: 5px;
          }
        }
      }
    }
  }

}



#settings, #createAcount {
  .header {
    background-color: #634FC3;
    padding: 0 3vw;
    margin: 0;
    height: 7vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    .leftSec {

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;

      .img {
        width: 7vw;
      }

      #backbtn {
        cursor: pointer;
      }


    }

    .centerSec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-left: -15vw;

      .settingsTitle {
        color: #fff;
        font-size: 2.1vw;
      }



    }

    .rightSec {
      img {
        width: 6vw;
        height: 6vw;
        object-fit: cover;
        border-radius: 100px;
        background-color: #FFFFFF;
        border: #F7BD4C solid 6px;
        cursor: pointer;
        box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;
        -webkit-box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;
        -moz-box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;

      }
    }
  }



  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 2vw 2vw;
    z-index: 99;

    .topmenu {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      cursor: pointer;

      .itemMenu {
        transition: 2s all;

        svg {
          // background-color: #fff;
          padding: 0 1vw;
          border-bottom: .11vw solid rgba(117, 199, 241, 0.20);
          width: 150px;
          height: 120px;
          position: relative;
          z-index: 99;
          transition: 2s all;
        }

        .titleMenu {
          color: rgba(79, 84, 114, 0.5);
          text-align: center;
          margin-top: 10px;
          position: relative;
          z-index: 99;
          font-size: 18px;
          padding-left: 10px;
        }

        .titleMenuActive {
          color: rgba(79, 84, 114, 1);
          text-align: center;
          margin-top: 10px;
          position: relative;
          z-index: 99;
          transition: .51s all;
          font-size: 18px;
          padding-left: 10px;
        }
      }
    }

    .Result {
      padding: 2vw 2vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;

    }
  }

}

#Search {
  .header {
    background-color: #634FC3;
    padding: 0 3vw;
    margin: 0;
    height: 7vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    .leftSec {

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;

      #backbtn {
        cursor: pointer;
        margin-left: 4vw;
      }

      .img {
        width: 7vw;
      }

      .SearchTitle {
        color: #fff;
        font-size: 1.5625vw;
      }
    }

    .centerSec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-left: -22vw;

      .all {
        background-image: url('../src/assets/img/all.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;
        transition: 0.5s all;

        .typetext {
          padding-bottom: 0.59vw;
          font-size: 1.2vw;
        }
      }

      .activeAll {
        background-color: #FFD145;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #FFE45E 5px solid;
      }

      .Songs {
        background-image: url('../src/assets/img/songs.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;
        transition: 0.5s all;

        .typetext {
          padding-bottom: 0.59vw;
          font-size: 1.2vw;
        }
      }

      .activeSongs {
        background-color: #E54564;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #FF5B7C 10px solid;
      }

      .activeStories {
        background-color: #AADC36;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #70C63E 10px solid;
      }

      .activeShows {
        background-color: #67C2FF;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #56AAFB 10px solid;
      }

      .Stories {
        background-image: url('../src/assets/img/Group 49811.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;

        .typetext {
          padding-bottom: 0.59vw;
          font-size: 1.2vw;
        }
      }

      .Shows {
        background-image: url('../src/assets/img/Group 4982.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;

        .typetext {
          padding-bottom: 0.5vw;
          font-size: 1.2vw;
        }
      }
    }

    .rightSec {
      >div{
        display: flex;
        align-items: center;
      }
      img {
        width: 6vw;
        height: 6vw;
        object-fit: cover;
        border-radius: 100px;
        background-color: #FFFFFF;
        border: #F7BD4C solid 6px;
        cursor: pointer;
        box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;
        -webkit-box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;
        -moz-box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;

      }

      .btn1 {
        background: rgb(255, 206, 0);
        background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 10px 3vw;
        border-radius: 50px;
        // width: 111vw;
        // box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        text-align: center;
        text-decoration: none;
        margin: 0 1vw;
      }
    }
  }



  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 2vw 2vw;
    z-index: 99;

    .rowTop {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;

      .countLeft {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        background-color: #65DAA3;
        width: 22vw;
        height: 4.5vw;
        padding: 0 2vw;
        border-radius: 20px;

        img {
          width: 3vw;
        }



        .itemCounter {
          color: #fff;
          text-align: left;
          padding: 0 1vw;
          font-size: 1.1vw;
          margin-left: 1vw;
          border-left: solid rgba(0, 0, 0, 0.09) 3px;
          height: 3vw;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
        }

        .itemCounterNoBorder {
          border-left: none;
        }

      }

      .centerAdd {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        background-color: rgba($color: #634FC3, $alpha: 0.13);
        width: 30%;
        height: 4vw;
        padding: 0 1vw;
        border-radius: 50px;

        .addBtn {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          cursor: pointer;
          color: #4F5472;
          font-size: 1vw;
          background-color: rgba($color: #634FC3, $alpha: 0.0);
          transition: all 0.9s;

          svg {
            width: 2.5vw;
            margin-right: 5px;

            g {
              circle {
                fill: none;
              }

            }
          }
        }

        .activeBtn {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          cursor: pointer;
          color: #fff;
          font-size: 1vw;
          padding: 0 10px;
          border-radius: 50px;
          height: 3vw;
          background-color: rgba($color: #634FC3, $alpha: 0.83);

          font-family: 'Somatic';

          svg {
            width: 2.5vw;
            margin-right: 5px;

            g {
              circle {
                stroke: #fff;
                fill: #fff;
              }

            }
          }
        }
      }

      .search {
        .input-text {
          box-sizing: border-box;
          border: 2px solid #C2D2F2;
          padding: 10px;
          transition: 0.5s all;
        }

        .input-text:focus {
          outline: none;
          border: 2px solid #7B67DB;
        }

        .input-text--search {
          background-image: url("../src/assets/img/Path 4.png");
          background-size: 18px, 50%;
          background-position: center left;
          /* legacy browser support */
          background-position: center left 10px, center left 38px;
          background-repeat: no-repeat;
          padding-right: 42px;
          padding-left: 35px;
          border-radius: 50px;

          width: 100%;
          height: 4vw;

          font-size: 18px;
          font-family: 'Somatic';
          /* Prevents text overlap on the icon */
        }
      }
    }

    .searchInput {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 75%;
      position: relative;
      margin: auto;

      .input-text {
        box-sizing: border-box;
        border: 2px solid #C2D2F2;
        padding: 10px;
        transition: 0.5s all;
      }

      .input-text:focus {
        outline: none;
        border: 2px solid #7B67DB;
      }

      .input-text--search {
        background-image: url("../src/assets/img/Path 4.png");
        background-size: 18px, 50%;
        background-position: center left;
        /* legacy browser support */
        background-position: center left 10px, center left 38px;
        background-repeat: no-repeat;
        padding-right: 42px;
        padding-left: 3vw;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        width: 100%;
        height: 70px;

        font-size: 18px;
        font-family: 'Somatic';
        /* Prevents text overlap on the icon */
      }

      .sesrch {}

      .genreitems {
        width: 100%;
        height: 70px;
        background-color: #fff;

        box-sizing: border-box;
        border: 2px solid #C2D2F2;
        padding: 10px;
        transition: 0.5s all;

        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        // justify-content: center;
        align-items: center;
        align-content: center;
        overflow-x: scroll;
        overflow-y: hidden;

        .item {
          background-color: rgba(123, 103, 218, .5);
          color: #fff;
          font-size: 16px;
          padding: 5px 6px;
          text-transform: capitalize;
          margin: 0 5px;
          border-radius: 15px;
          cursor: pointer;
          // min-width: 20%;
          min-width: 7vw;
          width: auto;
          font-weight: normal;
          height: auto;
        }

        .itemActive {
          background-color: rgba(123, 103, 218, 1);
          color: #fff;
          font-size: 16px;
          padding: 5px 6px;
          text-transform: capitalize;
          margin: 0 5px;
          border-radius: 15px;
          font-weight: normal;
          cursor: pointer;
          min-width: 7vw;
          width: auto;
          font-weight: normal;
          height: auto;
        }

      }

      .genreitems:checked {
        outline: none;
        border: 2px solid #7B67DB;
      }

      .genreitems::-webkit-scrollbar {
        height: 8px;

      }

      .genreitems::-webkit-scrollbar-track {
        border-bottom-right-radius: 30px;
        border-right: 2px solid #C2D2F2;
      }

      .genreitems::-webkit-scrollbar-thumb {
        background-color: rgba(123, 103, 218, .5);
        outline: none;
      }
    }

    .nocontent {
      font-size: 1.5vw;
      color: #634FC3;
    }

    .Result {
      padding: 2vw 2vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      svg {
        width: 20vw;
        cursor: pointer;
      }

      .item2 {
        position: relative;
        margin: 1.2vw;
        cursor: pointer;
        outline: none;

        .imgsList {
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          width: 14vw;
          height: 10.5vw;
          background-color: #FFD145;
          border-radius: 10px;

          img {
            position: relative;
            width: 6.5vw;
            height: 5vw;
            object-fit: cover;
            margin: 1px;
            border-radius: 10px;

          }
        }

        .titleitem {
          z-index: 9999;
          position: absolute;
          bottom: 5%;
          left: 5%;
          margin: auto;
          color: #fff;
          font-size: 20px;
          text-align: left;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          z-index: 9999;
          min-height: 99%;
          background: rgba(0, 0, 0, 0.21);
          // -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
          // -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
          box-shadow: inset 2px -50px 120px -16px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
        }
      }

      .item {
        position: relative;
        margin: 1.2vw;
        cursor: pointer;
        outline: none;

        .mainImg {

          width: 16vw;
          height: 10vw;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        #more {
          position: absolute;
          right: 5%;
          top: 5%;
          cursor: pointer;
          padding: 0 10px;
        }

        #info {
          position: absolute;
          right: 3%;
          bottom: 5%;
          cursor: pointer;
        }

        .gotocontent {
          position: absolute;
          left: 0;
          bottom: 0%;
          width: 85%;
          height: 100%;
          background-color: transparent;
          z-index: 99;
        }

        &::before {
          content: "";
          position: absolute;
          min-width: 100%;
          min-height: 99%;
          background: rgba(0, 0, 0, 0.11);
          -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
          box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
        }
      }
    }
  }

  .ResultPlaylist {
    padding: 2vw 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    svg {
      width: 20vw;
      cursor: pointer;
    }


    .item {
      position: relative;
      margin: 1.2vw;
      cursor: pointer;
      outline: none;
      transition: all 0.5s;

      .mainImg {

        width: 16vw;
        height: 10vw;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }



      .gotocontent {
        position: absolute;
        left: 0;
        bottom: 0%;
        width: 85%;
        height: 100%;
        background-color: transparent;
        z-index: 99;
      }

      &::before {
        content: "";
        position: absolute;
        min-width: 100%;
        min-height: 99%;
        background: rgba(0, 0, 0, 0.11);
        -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
        box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        background-image: url('../src/assets/img/Group 1827.png');
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.5s;
      }
    }

    .item2 {
      position: relative;
      margin: 1.2vw;
      cursor: pointer;
      outline: none;

      transition: all 0.5s;

      .mainImg {

        width: 16vw;
        height: 10vw;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }



      .gotocontent {
        position: absolute;
        left: 0;
        bottom: 0%;
        width: 85%;
        height: 100%;
        background-color: transparent;
        z-index: 99;
      }

      &::before {
        content: "";
        position: absolute;
        min-width: 100%;
        min-height: 99%;
        background: rgba(0, 0, 0, 0.11);
        -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
        box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        background-image: url('../src/assets/img/Group 1843.png');
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.5s;
      }
    }
  }

}

#home {

  .header {
    background-color: #634FC3;
    padding: 0 3vw;
    margin: 0;
    height: 7vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: fixed;
    width: 95%;
    z-index: 99999;
    box-shadow: 2px 0 8px rgba($color: #000, $alpha: 0.6);

    .leftSec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      img {
        width: 7vw;
      }

      margin-right: 8vw;

      .orangeSer {
        width: 40px;
        height: 40px;
        background-color: #FFD145;
        border-radius: 25px;
        margin: 0 8px;
        border: #FFE45E 5px solid;
        cursor: pointer;
      }

      .whiteSer {
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
        border-radius: 25px;
        margin: 0 8px;
        cursor: pointer;
      }

      .search {
        background-image: url('../src/assets/img/magnifying-glass.png');
        background-repeat: no-repeat;
        background-position: center;
        // background-size: contain;
      }

      .playList {
        background-image: url('../src/assets/img/Group 880.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .fav {
        background-image: url('../src/assets/img/Polygon 5.png');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .centerSec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      .Songs {
        background-image: url('../src/assets/img/songs.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;
        transition: 0.5s all;

        .typetext {
          padding-bottom: 0.59vw;
          font-size: 1.2vw;
        }
      }

      .activeSongs {
        background-color: #E54564;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #FF5B7C 10px solid;
      }

      .activeStories {
        background-color: #AADC36;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #70C63E 10px solid;
      }

      .activeShows {
        background-color: #67C2FF;
        // width: 10vw;
        padding: 0 2vw;
        border-radius: 20px;
        border: #56AAFB 10px solid;
      }

      .Stories {
        background-image: url('../src/assets/img/Group 49811.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;

        .typetext {
          padding-bottom: 0.59vw;
          font-size: 1.2vw;
        }
      }

      .Shows {
        background-image: url('../src/assets/img/Group 4982.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 4.5vw;
        width: 5.5vw;
        height: 6.0vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        color: #fff;
        font-size: 1.5vw;
        margin: 0 1.5vw;
        cursor: pointer;
        transition: 0.5s all;
        margin-top: 1vw;

        .typetext {
          padding-bottom: 0.5vw;
          font-size: 1.2vw;
        }
      }
    }

    .rightSec {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      .orangeSer {
        width: 40px;
        height: 40px;
        background-color: #FFD145;
        border-radius: 25px;
        margin: 0 8px;
        border: #FFE45E 5px solid;
        cursor: pointer;
      }

      .whiteSer {
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
        border-radius: 25px;
        margin: 0 8px;
        cursor: pointer;
      }

      .search {
        background-image: url('../src/assets/img/magnifying-glass.png');
        background-repeat: no-repeat;
        background-position: center;
        // background-size: contain;
      }

      .playList {
        background-image: url('../src/assets/img/Group 880.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      .fav {
        background-image: url('../src/assets/img/Polygon 5.png');
        background-repeat: no-repeat;
        background-position: center;
      }

      img {
        margin-left: 2vw;
        width: 5vw;
        height: 5vw;
        object-fit: cover;
        border-radius: 100px;
        background-color: #FFFFFF;
        border: #F7BD4C solid 6px;
        cursor: pointer;
        box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;
        -webkit-box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;
        -moz-box-shadow: -1px 0px 9px 6px rgba(0, 0, 0, 0.47) inset;

      }
    }
  }



  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 0 2vw;
    padding: 1vw 0;
    padding-bottom: 5.5vw;
    padding-top: 7vw;
    position: relative;

    .sliderSec {
      border-bottom: rgba(123, 103, 219, 0.21) solid 4px;
      padding-bottom: 2vw;

      .titleSlider {
        text-align: left;
        margin-bottom: 2vw;
        color: #7B67DB;
        font-size: 1.8vw;
        margin-left: 3vw;
        margin-top: 2vw;
        margin-bottom: 1vw;
      }

      .slider {

        transition: 2s all;

        .item {
          position: relative;

          cursor: pointer;
          outline: none;

          .mainImg {

            width: 16vw;
            height: 10vw;
            object-fit: cover;
            border-radius: 10px;
            // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          #more {
            position: absolute;
            right: 5%;
            top: 5%;
            cursor: pointer;
            padding: 0 10px;
            z-index: 100;
          }

          #info {
            position: absolute;
            right: 3%;
            bottom: 5%;
            cursor: pointer;
            z-index: 100;
          }

          .lockIcon {
            position: absolute;
            left: 0;
            bottom: 0%;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 99;
            border-radius: 10px;
            background-image: url('../src/assets/img/Lock icon.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 3vw;

          }

          .gotocontent {
            position: absolute;
            left: 0;
            bottom: 0%;
            width: 85%;
            height: 100%;
            background-color: transparent;
            z-index: 99;
          }

          .lableContentEn {
            position: absolute;
            bottom: 3%;
            left: 3% !important;
            color: #fff;
          }

          &::before {
            content: "";
            position: absolute;
            min-width: 100%;
            min-height: 99%;
            background: rgba(0, 0, 0, 0.021);
            -webkit-box-shadow: inset 2px -100px 80px -16px rgba(0, 0, 0, 0.45);
            -moz-box-shadow: inset 2px -100px 80px -16px rgba(0, 0, 0, 0.45);
            box-shadow: inset 2px -100px 80px -16px rgba(0, 0, 0, 0.45);
            border-radius: 10px;
          }
        }
      }
    }
  }
}

#header {
  color: #FFA130;
  background-color: transparent;
  background-image: url('../src/assets/img/Group 4981.png');
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100vw;
  /* margin: 5vw auto; */
  // overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: ease-out .7s;
  position: relative;
  // @media(max-width:1440px) {
  //   margin: 0 1vw auto;
  // }


  .title {
    font-size: 2.4vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 0%;
    margin-top: -5vw;

    .img {
      text-align: center;
      width: 15vw;
    }

    .textTitle {
      margin-top: 0%;
    }

    .btns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 1vw;
      font-family: 'Somatic';

      .btn1 {
        background: rgb(255, 206, 0);
        background-color: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
        background-image: url('../src/assets/img/Mask Group 57.png');
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 10px 0;
        border-radius: 50px;
        width: 200px;
        box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        text-align: center;
        text-decoration: none;
        margin: 0 1vw;
      }

      .btn2 {
        background: rgb(146, 145, 254);
        background-color: linear-gradient(180deg, rgba(146, 145, 254, 1) 0%, rgba(132, 133, 253, 1) 100%);
        background-image: url('../src/assets/img/Mask Group 57.png');
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        border: none;
        font-size: 18px;
        width: 100%;
        padding: 10px 0;
        border-radius: 50px;
        width: 200px;
        box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        outline: none;
        text-align: center;
        text-decoration: none;
        margin: 0 1vw;
      }
    }

    .btnsFree {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      margin-top: 1vw;

      button {
        background-color: transparent;
        border: none;
        text-align: center;
        font-size: 18px;
        font-family: 'Somatic';
        color: #FFA130;
        cursor: pointer;
        outline: none;
      }
    }

    .footer {
      text-align: center;
      color: #4F5472;
      font-size: 15px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 1vw;
      border-top: #707070 solid 1px;
    }
  }

  .stage {
    margin-left: 1vh;
    position: relative;

    img {}

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 20%;
    }

    #cloud2 {
      position: fixed;
      left: 20%;
      top: 30%;
    }


  }
}



hr {
  margin: 0;
  padding: 0;
  margin-top: 2vw;
  border-color: #dfe3ec;
  opacity: 0.2;
}






.App {
  text-align: center;
}


body {
  overflow-x: hidden;

}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7b6ace;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7b6ace;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.messageout-enter {
  opacity: 0.01;
}

/* ending ENTER animation */
.messageout-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

/* starting EXIT animation */
.messageout-exit {
  opacity: 1;
}

/* ending EXIT animation */
.messageout-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

#CreatePlaylist {

  position: fixed;
  width: 100vw;
  height: 100vw;
  z-index: 9999999999999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.66);
  transition: all 0.4s;

  .boxPopup {
    position: relative;
    background-color: transparent;
    width: 30%;
    height: 20vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 13%;
    // border-radius: 55px;
    // border: 15px solid transparent; 
    //  border-image:  url('../src/assets/img/Group 1415.png') 30 30 stretch;
    // -webkit-border-image:  url('../src/assets/img/Group 1415.png') 30 30 stretch;
    // -moz-border-image: url('../src/assets/img/Group 1415.png') 30 30 stretch;
    // -ms-border-image: url('../src/assets/img/Group 1415.png') 30 30 stretch;
    // -o-border-image: url('../src/assets/img/Group 1415.png') 30 30 stretch;
    background-image: url('../src/assets/img/bkpopup.png');
    -moz-background-image: url('../src/assets/img/bkpopup.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
  }

  .colseBtn {
    position: absolute;
    right: 32vw;
    top: 13%;
    font-size: 28px;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    padding: 10px;
    border-radius: 50px;
    width: 2vw;
    height: 2vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
  }



  .descriptionPopup {
    position: relative;
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: -8vw;
    padding: 0 5%;

    .descriptionPopup {
      font-size: 18px;
      margin-right: 2%;

      .btn {
        color: #fff;
        border: none;
        font-size: 1.3vw;
        padding: 0.8vw 0;
        border-radius: 20px;
        // box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        color: #7C68DC;
        text-decoration: none;
        background: #FFD145 5% 50%;
        font-family: 'Somatic';
        background-repeat: no-repeat;
        width: 50%;
        margin: auto;
        margin-top: 10px;
      }

      .input {
        text-align: center;
        margin: auto;

        position: relative;

        input {
          width: 22vw;
          height: 25px;
          padding: 0.7vw;
          font-family: 'Somatic';
          border: #C2D2F2 2px solid;
          border-radius: 25px;
          font-size: 15px;
          outline: none;
          margin-top: 1vw;

        }



      }
    }
  }

  .contentPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    margin-top: 12%;

    font-family: 'Somatic';

    .titlecontentPopup {
      font-size: 1.8vw;
      color: #4F5472;
    }


  }
}

#playlist {
  position: fixed;
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.66);

  z-index: 999999999999999;
  top: 0;
  left: 0;

  transition: all 0.4s;

  .boxPopup {
    position: relative;
    background-color: transparent;
    width: 25vw;
    height: 38vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;


    background-image: url('../src/assets/img/Group 5027.png');
    -moz-background-image: url('../src/assets/img/Group 5027.png');
    background-size: 25vw 30vw;
    background-repeat: no-repeat;
    padding: 1vw;
  }

  .colseBtn {
    position: absolute;
    right: 35vw;
    top: 7%;
    font-size: 28px;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    padding: 10px;
    border-radius: 50px;
    width: 2vw;
    height: 2vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
  }



  .descriptionPopup {
    position: absolute;
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0 0%;
    top: 8%;
    height: 75%;

    .descriptionPopup {
      font-size: 18px;
      margin-right: 2%;

      .list {

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        text-align: left;
        margin-top: 1vw;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        .itemList {
          width: 92%;
          background-color: rgba(0, 0, 0, 0.04);
          padding: 0.7vw;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          align-content: flex-start;
          margin: 5px 0;
          position: relative;
          cursor: pointer;

          .addtoplaylist {
            display: inline-block;
            border-radius: 50%;
            min-width: 2vw;
            min-height: 2vw;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.14);
            border: 1px solid rgba(0, 0, 0, 0.12);
          }



          .nameplaylist {
            font-size: 1.0vw;
            color: #7C68DC;
            width: 18vw;
            margin: 0 10px;
          }

          .imgsList {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            min-width: 5.5vw;
            min-height: 5.5vw;
            background-color: #FFD145;
            border-radius: 10px;

            img {
              position: relative;
              width: 2.5vw;
              height: 2.5vw;
              object-fit: cover;
              margin: 1px;
              border-radius: 10px;

            }

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, .01);
              -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
              -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
              box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              z-index: 999;
            }

          }

        }
      }

      .btn {
        color: #fff;
        border: none;
        font-size: 1.2vw;
        padding: 15px 0;
        border-radius: 20px;
        // box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        color: #7C68DC;
        text-decoration: none;
        background: #FFD145 url('../src/assets/img/Group 1521.png') no-repeat 5% 50%;
        ;
        background-repeat: no-repeat;
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .contentPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    margin-top: 5%;

    .titlecontentPopup {
      font-size: 1.8vw;
      color: #4F5472;
    }


  }
}

#infoConent {
  position: fixed;
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 999999;
  top: 0;
  left: 0;

  .boxPopup {
    position: relative;
    background-color: transparent;
    width: 46.5%;
    height: 35vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;

    background-image: url('../src/assets/img/bkpopup.png');
    -moz-background-image: url('../src/assets/img/bkpopup.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 1vw;


    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
  }

  .colseBtn {
    position: absolute;
    right: 23vw;
    top: 11vw;
    font-size: 1.5vw;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    padding: 10px;
    border-radius: 50px;
    width: 2vw;
    height: 2vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
  }

  .tagimg {
    position: absolute;
    top: 12%;
    right: 15%;
    background-color: #7C69DC;
    padding: 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .agerate {
    position: absolute;
    top: 15%;
    right: 30%;
    background-color: #FDC12B;
    padding: 0.5vw;
    border-radius: 15px;
    width: auto;
    color: #fff;
  }

  .play {
    width: 3vw;
    height: 3vw;
    background-image: url('../src/assets/img/Group 422.png');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #65DAA3;
    border-radius: 100px;
    padding: 20px;
    position: absolute;
    bottom: 22%;
    right: 8%;
    cursor: pointer;

  }

  .tags {
    position: absolute;
    bottom: 22%;
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-weight: lighter;

    .itemtag {
      background-color: #ACDCF8;
      padding: 8px 20px;
      color: #fff;
      font-size: 1.1vw;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-weight: lighter;
      margin: 5px 5px;
    }
  }

  .descriptionPopup {
    position: relative;
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-left: -1%;
    text-align: left;
    margin-top: -9vw;
    padding: 0 5%;

    .descriptionPopup {
      font-size: 1.1vw;
      color: #6D92AF;
      margin-right: 2%;
      margin-left: 1.5%;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  .contentPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 15%;
    margin-bottom: 5%;
    margin-left: 8%;

    .titlecontentPopup {
      font-size: 1.5vw;
      color: #4F5472;
      margin-right: 2%;
    }

    .GenrecontentPopup {
      font-size: 1vw;
      color: #4f547269;
      padding-top: .5%;
    }
  }
}



#LoginPopup {
  position: fixed;
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 999999;
  top: 0;
  left: 0;

  .boxPopup {
    position: relative;
    background-color: transparent;
    width: 46.5%;
    height: 35vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;

    background-image: url('../src/assets/img/bkpopup.png');
    -moz-background-image: url('../src/assets/img/bkpopup.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 1vw;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
  }

  .colseBtn {
    position: absolute;
    right: 23%;
    top: 11%;
    font-size: 28px;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    padding: 10px;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
  }






  .btns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: 'Somatic';



  }

  .contentPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    font-family: 'Somatic';

    .titlecontentPopup {
      font-size: 30px;
      color: #4F5472;
      margin-right: 2%;
    }

    .btn1 {
      background: rgb(255, 206, 0);
      background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
      color: #fff;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 50px;
      width: 25vw;
      box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      text-align: center;
      text-decoration: none;
      margin-top: 2vw;
      background: rgb(255, 206, 0);
      background-image: url('../src/assets/img/Mask Group 57.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .btn2 {
      background: rgb(146, 145, 254);
      background-image: url('../src/assets/img/Mask Group 57.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      color: #fff;
      border: none;
      font-size: 18px;
      width: 25vw;
      padding: 10px 0;
      border-radius: 50px;
      box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      outline: none;
      text-align: center;
      text-decoration: none;
      margin-top: 1vw;
    }
  }
}







#under {
  position: fixed;
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 999999;
  top: 0;
  left: 0;
  transition: all 1s;

  .boxPopup {
    position: relative;
    background-color: rgba($color: #65DAA3, $alpha: 0.8);
    width: 30%;
    height: 4vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 1%;
    border-radius: 55px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
  }

  .colseBtn {
    position: absolute;
    right: 28%;
    top: 12%;
    font-size: 28px;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    padding: 10px;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
  }




  .descriptionPopup {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;

    .descriptionPopup {
      font-size: 18px;
      color: #fff;
    }
  }

  .contentPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .titlecontentPopup {
      font-size: 1.3vw;
      color: #fff;
      margin-right: 2%;
    }

    .GenrecontentPopup {
      font-size: 16px;
      color: #fff;
      padding-top: 1.5%;
    }
  }
}

#infoConent2 {
  position: fixed;
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 999999;
  top: 0;
  left: 0;
  transition: all 0.8;

  .boxPopup {
    position: relative;
    background-color: transparent;
    width: 46.5%;
    height: 35vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;

    background-image: url('../src/assets/img/bkpopup.png');
    -moz-background-image: url('../src/assets/img/bkpopup.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 1vw;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .colseBtn {
    position: absolute;
    right: 23vw;
    top: 11vw;
    font-size: 1.5vw;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    padding: 10px;
    border-radius: 50px;
    width: 2vw;
    height: 2vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
  }

  .titleContent {
    font-size: 2.2vw;
    color: #4F5472;
    margin-bottom: 30px;
  }

  .btns2 {
    display: flex;
    flex-direction: column;

    .btnfav {
      color: #fff;
      border: none;
      font-size: 1.3vw;
      padding: 1.3vw 0;
      border-radius: 20px;
      // box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      color: #7C68DC;
      text-decoration: none;
      margin: 10px 0;
      background: #FFD145 url('../src/assets/img/Polygon 61.png') no-repeat 5% 50%;
      ;
      background-repeat: no-repeat;

    }

    .btnfavActive {
      color: #fff;
      border: none;
      font-size: 1.3vw;
      padding: 1.3vw 0;
      border-radius: 20px;
      // box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      color: #fff;
      text-decoration: none;
      margin: 10px 0;
      background: #7C68DC url('../src/assets/img/Polygon 6.png') no-repeat 5% 50%;
      ;
      background-repeat: no-repeat;

    }

    .btnPlaylist {
      color: #fff;
      border: none;
      font-size: 1.3vw;
      padding: 1.3vw 10vw;
      border-radius: 20px;
      // box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      color: #7C68DC;
      text-decoration: none;
      margin: 10px 0;
      background: #FFD145 url('../src/assets/img/Group 1522.png') no-repeat 5% 50%;
      ;
      background-repeat: no-repeat;

    }

    .btnYellow{
      color: #fff;
      border: none;
      font-size: 1.3vw;
      padding: 1.3vw 10vw;
      border-radius: 20px;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      color: #7C68DC;
      text-decoration: none;
      margin: 10px 0;
      background-color: #FDC12B;
    }
    .btnDisabled{
      pointer-events: none;
      background-color: #c4c4c4;
    }
  }



  .tags {
    position: absolute;
    bottom: 25%;
    left: 18%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: lighter;

    .itemtag {
      background-color: #ACDCF8;
      padding: 8px 20px;
      color: #fff;
      font-size: 16px;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-weight: lighter;
      margin: 0 5px;
    }
  }

  .descriptionPopup {
    position: relative;
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-left: -1%;
    text-align: left;
    margin-top: -9vw;
    padding: 0 10%;

    .descriptionPopup {
      font-size: 18px;
      color: #6D92AF;
      margin-right: 2%;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  .contentPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 16%;
    margin-left: 18%;

    .titlecontentPopup {
      font-size: 30px;
      color: #4F5472;
      margin-right: 2%;
    }

    .GenrecontentPopup {
      font-size: 16px;
      color: #6D92AF;
      padding-top: 1.5%;
    }
  }
}




#conentViewer {


  overflow: hidden;

  .stage {
    margin-left: 1vh;
    position: relative;

    #cloud1 {
      position: fixed;
      right: 20%;
      top: 30%;
      z-index: 0;
    }

    #cloud2 {
      position: fixed;
      left: 15%;
      top: 50%;
      z-index: 0;
    }




  }

  .bodyHome {
    margin: 2vw 2vw;
    z-index: 99;

    .sliderSec {
      border-bottom: rgba(123, 103, 219, 0.21) solid 4px;
      padding-bottom: 2vw;

      .titleSlider {
        text-align: left;
        margin-bottom: 2vw;
        color: #7B67DB;
        font-size: 30px;
        margin-left: 4vw;
        margin-top: 2vw;
        margin-bottom: 1vw;
      }

      .slider {

        transition: 2s all;

        .item {
          position: relative;

          cursor: pointer;
          outline: none;

          .mainImg {

            width: 16vw;
            height: 10vw;
            object-fit: cover;
            border-radius: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          #more {
            position: absolute;
            right: 5%;
            top: 5%;
            cursor: pointer;
            padding: 0 10px;
          }

          #info {
            position: absolute;
            right: 3%;
            bottom: 5%;
            cursor: pointer;
          }

          &::before {
            content: "";
            position: absolute;
            min-width: 100%;
            min-height: 99%;
            background: rgba(0, 0, 0, 0.01);
            -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
            box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
          }
        }
      }
    }
  }

  .videoShow {
    margin-top: 4vw;
    z-index: 999;
    position: relative;
    border-radius: 10px;
    outline: none;
    width: 50%;
    height: 54vh;
    object-fit: contain;

  }

  .realtedVideo {
    width: 100%;
    height: 8vw;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #7B67DB;
    padding-top: 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;


    .sliderSec2 {
      border-bottom: rgba(123, 103, 219, 0.21) solid 4px;
      padding-bottom: 2vw;

      padding: 0 2vw;

      .titleSlider {
        text-align: left;
        margin-bottom: 2vw;
        color: #7B67DB;
        font-size: 30px;
        margin-left: 4vw;
        margin-top: 2vw;
        margin-bottom: 1vw;
      }

      .slider {

        transition: 2s all;

        .item {
          position: relative;

          cursor: pointer;
          outline: none;

          .mainImg {

            width: 10vw;
            height: 6vw;
            object-fit: cover;
            border-radius: 10px;
          }

          #more {
            position: absolute;
            right: 5%;
            top: 5%;
            cursor: pointer;
            padding: 0 10px;
          }

          #info {
            position: absolute;
            right: 3%;
            bottom: 5%;
            cursor: pointer;
          }

          &::before {
            content: "";
            position: absolute;
            min-width: 100%;
            min-height: 99%;
            background: rgba(0, 0, 0, 0.01);
            -webkit-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 2px 80px 160px -16px rgba(0, 0, 0, 0.25);
            box-shadow: inset 2px 100px 120px -16px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
          }
        }

        .itemActive {
          cursor: auto;
          border: #fff 3px solid;
          border-radius: 10px;
        }
      }
    }
  }

  .controlVideo {
    position: absolute;
    top: 3%;
    right: 0;
    left: 0;
    background-color: #fff;
    width: 35%;
    height: 6vh;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0px 15px 34px rgba(41, 33, 87, 0.13);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    #homepage {
      cursor: pointer;
    }

    #fav {
      cursor: pointer;
    }

    #playandpr {
      cursor: pointer;
      width: 1.3vw;
    }

    #repetition {
      cursor: pointer;
    }


    #backVideo {
      cursor: pointer;
    }

    #nextVideo {
      cursor: pointer;
    }

    #playlistBtn {
      cursor: pointer;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.MenuItem {
  font-size: 18px;
  font-family: 'Somatic';
  padding-left: 10px;
}

.player-wrapper {
  position: relative;
  padding-top: 43.25%;

  /* Player ratio: 100 / (1280 / 720) */
  .btnbar {
    position: absolute;
    bottom: 20%;
    font-size: 1vw;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.63);
    width: 53.8%;
    height: 8%;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    .svg {
      padding: 5px 10px;
      cursor: pointer;
      width: 5%;
      height: 3vw;
    }

    input[type=text] {
      font-family: 'Rubik', sans-serif;
      color: #5e5e5e;
      margin-top: 10px;
      border: 1px solid black;
      padding: 15px 10px;
      width: 110px;
      font-size: 20px;
      text-align: center;
      z-index: 1;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    }


    input[type="range"] {
      height: 20px;
      // border: 2px solid #fff;
      border-radius: 10px;
      margin: auto;
      cursor: pointer;
      outline: none;
    }

    /*Chrome*/
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      input[type='range'] {
        overflow: hidden;
        width: 28vw;
        -webkit-appearance: none;
        background-color: #d3d3d3;
        cursor: pointer;
      }

      input[type='range']::-webkit-slider-runnable-track {
        height: 25px;
        /* Specified height */
        -webkit-appearance: none;
        color: #13bba4;
        margin-top: -1px;
        cursor: pointer;
      }

      input[type='range']::-webkit-slider-thumb {
        width: 25px;
        /* Set a specific slider handle width */
        -webkit-appearance: none;
        height: 25px;
        /* Specified height */
        cursor: ew-resize;
        background: #FFFFFF;
        box-shadow: -100vw 0 0 100vw #FDC12B;
        border-radius: 25px;
        cursor: pointer;
      }


      /** FF*/
      input[type="range"]::-moz-range-progress {
        background-color: #FDC12B;
        box-shadow: -100vw 0 0 100vw #FDC12B;
        border-radius: 25px;
      }

      input[type="range"]::-moz-range-track {
        background-color: #d3d3d3;
      }

      /* IE*/
      input[type="range"]::-ms-fill-lower {
        background-color: #FDC12B;
        border-radius: 25px;
      }

      input[type="range"]::-ms-fill-upper {
        background-color: #d3d3d3;
      }

    }


    .bar1 {
      background-color: #939BA0;
      width: 38vw;
      height: 1.5vw !important;
      border-radius: 20px;
      position: relative;

      .activeBar {
        position: absolute;
        background-color: #FDC12B;

        height: 1.5vw;
        border-radius: 20px;

      }
    }

    .Seconds {
      color: #fff;
      padding-left: 5px;
      font-family: 'Somatic';
      font-size: 1.1vw;
    }

    .fullSec {
      position: relative;

      svg {
        padding: 0 1vw;
        cursor: pointer;
      }
    }
  }
}

.poseBtnHide {
  background-color: transparent;
  width: 50vw;
  height: 20vw;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  z-index: 99999999;
  margin: auto;
}

.player-wrapper-full {
  position: relative;
  width: 100%;
  height: 92vh;
  overflow: hidden;
  padding: 0;
  margin: 0;

  /* Player ratio: 100 / (1280 / 720) */
  .btnbar {
    position: absolute;
    bottom: 0%;
    font-size: 1vw;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.63);
    width: 100%;
    height: 8%;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0;

    .svg {
      padding: 5px 10px;
      cursor: pointer;
      width: 5%;
      height: 3vw;
    }

    input[type=text] {
      font-family: 'Rubik', sans-serif;
      color: #5e5e5e;
      margin-top: 10px;
      border: 1px solid black;
      padding: 15px 10px;
      width: 110px;
      font-size: 20px;
      text-align: center;
      z-index: 1;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    }


    input[type="range"] {
      height: 20px;
      // border: 2px solid #fff;
      border-radius: 10px;
      margin: auto;
      cursor: pointer;
      outline: none;
    }

    /*Chrome*/
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      input[type='range'] {
        overflow: hidden;
        width: 62vw;
        -webkit-appearance: none;
        background-color: #d3d3d3;
        cursor: pointer;
      }

      input[type='range']::-webkit-slider-runnable-track {
        height: 25px;
        /* Specified height */
        -webkit-appearance: none;
        color: #13bba4;
        margin-top: -1px;
        cursor: pointer;
      }

      input[type='range']::-webkit-slider-thumb {
        width: 25px;
        /* Set a specific slider handle width */
        -webkit-appearance: none;
        height: 25px;
        /* Specified height */
        cursor: ew-resize;
        background: #FFFFFF;
        box-shadow: -100vw 0 0 100vw #FDC12B;
        border-radius: 25px;
        cursor: pointer;
      }


      /** FF*/
      input[type="range"]::-moz-range-progress {
        background-color: #FDC12B;
        box-shadow: -100vw 0 0 100vw #FDC12B;
        border-radius: 25px;
      }

      input[type="range"]::-moz-range-track {
        background-color: #d3d3d3;
      }

      /* IE*/
      input[type="range"]::-ms-fill-lower {
        background-color: #FDC12B;
        border-radius: 25px;
      }

      input[type="range"]::-ms-fill-upper {
        background-color: #d3d3d3;
      }

    }


    .bar1 {
      background-color: #939BA0;
      width: 38vw;
      height: 1.5vw !important;
      border-radius: 20px;
      position: relative;

      .activeBar {
        position: absolute;
        background-color: #FDC12B;

        height: 1.5vw;
        border-radius: 20px;

      }
    }

    .Seconds {
      color: #fff;
      padding-left: 5px;
      font-family: 'Somatic';
      font-size: 15px;
    }

    .fullSec {
      position: relative;

      svg {
        padding: 0 1vw;
        cursor: pointer;
      }
    }
  }
}

.react-player {
  position: absolute;

  left: 0;
  border-radius: 20px;
}



.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  .popupBox {
    position: relative;
    background-color: transparent;
    width: 30%;
    height: 25vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 18%;
    background-image: url('../src/assets/img/Group 5027.png');
    -moz-background-image: url('../src/assets/img/Group 5027.png');
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .titleBox {
      text-align: center;
      font-size: 1.5vw;
      margin-top: -5vw;
    }

    .btns {
      margin-top: 2vw;
      width: 100vw;

      .btn1 {
        background: #65DAA3;
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 10px 35px;
        border-radius: 50px;
        box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        text-align: center;
        text-decoration: none;
        margin: 0 .51vw;
      }

      .btn2 {
        background: #FF3B41;
        color: #fff;
        border: none;
        font-size: 18px;
        width: 100%;
        padding: 10px 35px;
        border-radius: 50px;
        box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        outline: none;
        text-align: center;
        text-decoration: none;
        margin: 0 .51vw;
        cursor: pointer;
      }
    }

  }
}

.titleitem {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  font-size: 20px;
}

.titleitem2 {
  font-size: 1.2vw;
  text-align: left;
  margin-left: 1vw;
  margin-top: -0.5vw;
  color: #4F5472;
  cursor: pointer;
  width: 90%;

}

.fadeInAnmthion {
  transition: opacity 2.2s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.fadeInAnmthionActive {
  opacity: 1;
  height: auto;
}

.pointer {
  cursor: pointer;
}

.Subscription {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  position: relative;
  margin-bottom: 4vw;

  .plan {

    margin-top: 1vw;
    margin-bottom: 4vw;
    background-image: url('../src/assets/img/Path 2492.png');
    background-repeat: no-repeat;
    background-position: top;
    background-color: #F4FAFF;
    background-size: contain;
    width: 18vw;
    height: 28vw;
    font-size: 1vw;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    margin: 0 1vw;
    position: relative;
  }

  .titlePlan {
    font-size: 1.2vw;
    color: #fff;
    padding: 1vw 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: url('../src/assets/img/Mask Group 61.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .detPlan {
    color: #8CACC1;

    position: absolute;
    bottom: 5%;

    .itemDet {
      display: flex;
      flex-direction: row;
      text-align: left;
      padding-left: 1vw;
      width: 80%;
      padding-bottom: 1vw;

      .ritDet {
        padding-left: 1vw;

      }

      .leftDet {
        font-size: 1.3vw;

        .Cimg {
          max-width: none;
        }
      }
    }
  }

  .btnPlan {
    position: absolute;
    bottom: -1.5vw;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    width: 40%;
    margin: auto;
    padding: 0.8vw 0.5vw;
    border-radius: 50px;
    cursor: pointer;
    background-image: url('../src/assets/img/Group 5021.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .disabledBtnPlan{
    background-color: #707070 !important;
    cursor: not-allowed;
  }

  .optionPlan {
    margin-top: 4vw;
    color: #6A91AC;
    font-size: 2.5vw;
    font-family: 'Somatic', 'ArbFONTS-DroidKufi';
    font-weight: bold;

    span {
      font-family: 'Material-Design-Iconic-Font';
    }

    .burmanRadio {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      border-bottom: 3px solid #ECF5FC;
      padding-bottom: 1vw;

      &__input {
        display: none;

        &:checked~.burmanRadio__label::after {
          opacity: 1;
          transform: scale(1);
        }
      }

      &__label {
        cursor: pointer;
        line-height: 30px;
        position: relative;
        margin-right: 35px;
        padding-right: 35px;

        &::before,
        &::after {
          border-radius: 50%;
          position: absolute;
          top: -1.5px;
          right: -35px;
          transition: all .3s ease-out;
          z-index: 2;
        }

        &::before {
          content: "";
          border: 1.5px solid #5AC9FA;
          width: 1.7vw;
          height: 1.7vw;
        }

        &::after {
          content: "";
          background: #7B67DB;
          background-image: url('../src/assets/img/Path 2489.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 1.1vw;
          font-weight: bolder;
          border: 1.5px solid #5AC9FA;
          color: #FFF;
          font-family: 'Material-Design-Iconic-Font';
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          width: 1.7vw;
          height: 1.7vw;
          transform: scale(0);
        }

        &:hover {
          &::before {
            border-color: #5AC9FA;
          }
        }
      }
    }

  }
}

.checkout {
  position: relative;
  width: auto;
  margin-bottom: 10vw;

  .detal {
    background-color: #fff;
    width: 100%;
    min-height: 10vw;
    height: auto;
    margin: 1vw 0;
    border-radius: 25px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.11);
    color: #4F5472;
    font-size: 1.2vw;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1vw 1.8vw;

    .row {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: 1vw 0;
    }

    .inputdet {
      width: 100%;
      border-right: 2px solid rgba(0, 0, 0, 0.08);
      padding-right: 1vw;
    }
  }

  .detal1 {
    width: 100%;
    height: auto;
    margin: 1vw 0;
    border-radius: 25px;
    color: #4F5472;
    font-size: 1vw;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    direction: ltr;
    align-content: center;

    .row {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: 1vw 0;
    }

    .inputdet {
      width: 100%;
      border-right: 2px solid rgba(0, 0, 0, 0.08);
      padding-right: 1vw;
    }
  }

  .paypal {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;


  }
}

.imgLogo {
  width: 7vw;
  margin: 0;
}

.SubscriptionSetting {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  // background-image: url('../src/assets/img/23213213.png');
  // background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  background-size: contain;
  height: 20vw;
  width: 30vw;
  position: relative;

  .svg {
    width: 5vw;
    height: 5vw;
    background-color: #B6E2FE;
    border-radius: 50%;
    padding: 1vw;

  }

  .minTilie {
    font-size: 1.5vw;
    color: #222;
  }

  .subTilie {
    font-size: 1vw;
    width: 40%;
    color: #333;
  }

  .btn1 {
    background: rgb(255, 206, 0);
    background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
    color: #fff;
    border: none;
    font-size: 1vw;
    padding: 0.5vw 0;
    border-radius: 50px;
    width: 20vw;
    box-shadow: 1px 3px 9px #BABCF0;
    font-family: 'Somatic';
    cursor: pointer;
    outline: none;
    text-align: center;
    text-align: center;
    text-decoration: none;
    margin-top: 1vw;
    background: rgb(255, 206, 0);
    background-image: url('../src/assets/img/Mask Group 57.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.contentPopupStting {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  .titlecontentPopup {
    font-size: 30px;
    color: #4F5472;
    margin-right: 2%;
  }

  .Notification {
    color: #7B67DB;
    margin-top: 2vw;
  }





  .btn1 {
    background: rgb(255, 206, 0);
    background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
    color: #fff;
    border: none;
    font-size: 18px;
    padding: 10px 0;
    border-radius: 50px;
    width: 20vw;
    box-shadow: 1px 3px 9px #BABCF0;
    font-family: 'Somatic';
    cursor: pointer;
    outline: none;
    text-align: center;
    text-align: center;
    text-decoration: none;
    margin-top: 2vw;
    background: rgb(255, 206, 0);
    background-image: url('../src/assets/img/Mask Group 57.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .btn2 {
    background: rgb(146, 145, 254);
    background-image: url('../src/assets/img/Mask Group 57.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    border: none;
    font-size: 18px;
    width: 20vw;
    padding: 10px 0;
    border-radius: 50px;
    box-shadow: 1px 3px 9px #BABCF0;
    font-family: 'Somatic';
    outline: none;
    text-align: center;
    text-decoration: none;
    margin-top: 1vw;
  }
}

.rec-carousel-item {
  width: 20%;
}

.my-facebook-button-class {
  border: none;
  background-color: transparent;
  width: auto;
  color: transparent;
  font-size: 0px;
}

.TwitterLogin {
  background-color: transparent;
  border: none;

}

.loadingSVG {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin: auto;
  width: 35vw;

}

.logoincontent {
  width: 5vw;
  position: absolute;
  left: 5%;
  width: 7vw;
  top: 2%;
  z-index: 99;
  display: flex;
  align-items: center;
  img{
    max-height: 100%;
  }
}

.titleContentalert {
  position: absolute;
  top: 12%;
  left: 0;
  right: 0;
  width: 25%;
  margin: auto;
  background-color: rgba($color: #000, $alpha: 0.6);
  padding: 1vw;
  z-index: 999;
  font-size: 1vw;
  color: #fff;
  border-radius: 25px;
}

.formeditImgProfile {
  background-color: rgb(233, 233, 248);
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  min-height: 100%;
  padding: 2vw 1vw;
  z-index: 999;
  box-shadow: 0 0 8px rgba($color: #634FC3, $alpha: 0.4);
  padding-top: 4vw;

  .closeIcon {
    position: absolute;
    top: 2%;
    right: 4%;
    font-size: 1.2vw;
    z-index: 99;
    color: #FFB601;
    background-color: #7C68DC;
    border-radius: 50px;
    width: 3.5vw;
    height: 3.5vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-family: 'Somatic';
    font-weight: bolder;
    margin: auto;
  }

  .textTitle {
    margin-top: 5%;
    text-align: center;
    color: #4F5472;
    font-family: 'Somatic';
    margin-bottom: 1vw;
  }

  .imgProfile {
    position: relative;
    width: 50%;
    margin: auto;

    img {
      width: 10vw;
      height: 10vw;
      background-color: #B6E2FE;
      border-radius: 100px;
    }

    .editimg {
      position: absolute;
      top: 0;
      right: 35%;
      background-color: #fff;
      background-image: url('../src/assets/img/Group 1364.png');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      -moz-border-radius: 75px;
      -webkit-border-radius: 75px;
      width: 3.5vw;
      height: 3.5vw;
    }

  }

  .avatar {
    margin-top: 2vw;
    width: 60vw;
    margin: auto;

    .titleAvatar {
      font-size: 18px;
      color: #4F5472;
    }

    .listAvatar {
      background-color: #fff;
      width: 100%;
      min-height: 10vw;
      height: auto;
      margin: 1vw 0;
      border-radius: 25px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.11);

      img {
        cursor: pointer;
        width: 8vw;
        // background-color: #13bba4;
        border-radius: 50%;
        margin: 1vw 1vw;
      }
    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin-top: 1vw;
    font-family: 'Somatic';

    .btn1 {
      background: rgb(255, 206, 0);
      background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
      color: #fff;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 50px;
      width: 31.5vw;
      box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      text-align: center;
      text-decoration: none;
      margin-top: 2vw;
    }

    .disbtn {
      background: rgba($color: #999, $alpha: 0.9);
      color: #fff;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 50px;
      width: 31.5vw;
      // box-shadow: 1px 3px 9px #BABCF0;
      font-family: 'Somatic';
      cursor: pointer;
      outline: none;
      text-align: center;
      text-align: center;
      text-decoration: none;
      margin-top: 2vw;
    }

  }

}

body {
  position: relative;
}

#footerComponent {
  text-align: center;
  color: #4F5472;
  font-size: 15px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 98vw;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding: 1.1vw;
  border-top: #70707041 solid 1px;

  .rightSec {
    // width: 33%;
    a {
      padding-right: 1.5vw;
      cursor: pointer;
    }
  }

  .centerSec {
    // width: 33%;

    a {
      padding-right: 10px;

      img {
        width: 2vw;
      }
    }
  }

  .leftSec {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
  }


  .input {
    text-align: center;
    margin: auto;

    position: relative;

    input {
      width: 88%;
      height: 25px;
      padding: 10px;

      font-family: 'Somatic';
      border: #C2D2F2 2px solid;
      border-radius: 25px;
      font-size: 15px;
      margin-top: 10px;
      outline: none;
    }


    textarea {
      width: 88%;
      padding: 10px;
      height: 8vw;
      font-family: 'Somatic';
      border: #C2D2F2 2px solid;
      border-radius: 25px;
      font-size: 15px;
      margin-top: 10px;
      outline: none;
    }

    input::placeholder {
      font-family: 'Somatic';
    }


  }

  .SucMsg {
    background-color: #65DAA3;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: center;
    width: auto;
    padding: .61vw 1vw;
    border-radius: 15px;
    margin: auto;
    text-align: right;

    width: 85%;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    z-index: 999;

    svg {
      margin-left: 1vw;
    }
  }

  .errorMsg {
    background-color: #FD7777;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: center;
    width: auto;
    padding: .61vw 1vw;
    border-radius: 15px;
    margin: auto;
    text-align: right;
    z-index: 999;
    width: 85%;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;

    svg {
      margin-left: 1vw;
    }
  }

  .btnCountact {
    background: rgb(255, 206, 0);
    background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
    color: #fff;
    border: none;
    font-size: 1.2vw;
    padding: 0.8vw 0;
    border-radius: 50px;
    width: 90%;
    box-shadow: 1px 3px 9px #BABCF0;

    font-family: 'Somatic';
    cursor: pointer;
    outline: none;
    text-align: center;
    text-align: center;
    text-decoration: none;
    margin: auto;
    margin-top: 1vw;

  }

  #infoConent {
    position: fixed;
    width: 100vw;
    height: 100vw;
    background-color: rgba(0, 0, 0, 0.66);
    z-index: 99999999999999;
    top: 0;
    left: 0;

    .boxPopup {
      position: relative;
      background-color: transparent;
      width: 50%;
      height: 35vw;
      left: 0;
      right: 0;
      margin: auto;
      top: 10%;
      border-radius: 25px;
      border: 15px solid transparent;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      background-image: url('../src/assets/img/Group 5027.png');
      -moz-background-image: url('../src/assets/img/Group 5027.png');
      background-size: contain;
      background-repeat: no-repeat;
    }

    .colseBtn {
      position: absolute;
      right: 22%;
      top: 7%;
      font-size: 1.5vw;
      z-index: 99;
      color: #FFB601;
      background-color: #7C68DC;
      padding: 10px;
      border-radius: 50px;
      width: 2vw;
      height: 2vw;
      text-align: center;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      cursor: pointer;
      font-family: 'Somatic';
      font-weight: bolder;
    }

    .descriptionPopup2233 {
      font-size: 0.9vw;
      color: #6D92AF;
      text-align: right;
      margin: 0 4vw;
      font-weight: normal;
      width: 85%;
    }

    .descriptionPopup22 {
      font-size: 0.9vw;
      color: #6D92AF;
      padding: 2vw 0;
      text-align: left;
      height: 20vw;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: 0 4vw;
      font-weight: normal;
      width: 85%;
    }

    .titlecontentPopup22 {
      font-size: 2vw;
      color: #4F5472;
      padding: 0 4vw;
      padding-top: 3vw;
    }
  }
}

.MobileView {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  background-image: url('../src/assets/img/Group 5036.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  .logo {
    width: 2vw;
  }
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}

.popupSub {
  position: absolute;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;

  .colsebtn {
    background-color: #7C68DC;
    position: fixed;
    top: 25%;
    right: 30%;
    width: 3vw;
    height: 3vw;
    z-index: 9999999999999;
    color: #FFB601;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    font-size: 1.2vw;
    font-weight: bold;
    cursor: pointer;
  }

  .boxPopup {
    position: fixed;
    top: 30%;
    z-index: 9999999999999;
    left: 0;
    right: 0;
    margin: auto;
    width: 35vw;
    height: 20vw;
    background-image: url('../src/assets/img/bkpopup.png');
    -moz-background-image: url('../src/assets/img/bkpopup.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 1vw;

    img {
      margin-top: 3vw;
      width: 7vw;
    }

    .textPopup {
      font-size: 1.5vw;
      color: #4F5472;
    }


    .btns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      margin-top: 1vw;
      font-family: 'Somatic';

      .btn1 {
        background: rgb(255, 206, 0);
        background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 10px 0;
        border-radius: 50px;
        width: 25vw;
        box-shadow: 1px 3px 9px #BABCF0;
        font-family: 'Somatic';
        cursor: pointer;
        outline: none;
        text-align: center;
        text-align: center;
        text-decoration: none;
        margin-top: 1vw;
        font-family: 'Somatic';
        z-index: 9999999999;
      }
    }
  }

}

.lockIcon {
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  border-radius: 10px;
  background-image: url('../src/assets/img/Lock icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3vw;

}

.buttonSkip {
  color: #111;
  font-weight: bold;
  text-align: right;
  margin-right: 22vw;
  cursor: pointer;
}

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: -1vw;
  gap: 30px;
//   margin-left: 2vw;
  .hyperpay {
    cursor: pointer;

    img {
      // border: #1a72fe solid 1px;
      // border-radius: 25px;
    }
  }
}


.errorhyperpay {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #F74F58;
  direction: ltr;
  margin-top: 3vw;
}

.succhyperpay {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: rgb(101, 218, 163);
  direction: ltr;
  margin-top: 3vw;
}

.formForPay {
  width: 85%;
  padding: 2vw;
  margin-right: -2vw;
  border-radius: 10px;
  margin-top: 0vw;
  font-family: 'Somatic';

  .MuiFormControl-root {
    margin: 8px 0 !important;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .MuiInputLabel-outlined {
    left: 60% !important;
  }

  .titleForm {
    color: #888;
    font-size: 16px;
  }

  label {
    font-size: 16px;
    text-align: left;
    color: #888;
    padding-bottom: 5px;
  }



  .btns2 {
    background: rgb(255, 206, 0);
    background: linear-gradient(180deg, rgba(255, 206, 0, 1) 0%, rgba(255, 179, 2, 1) 100%);
    color: #fff;
    border: none;
    font-size: 16px;
    padding: 5px 0;
    border-radius: 50px;
    width: 18vw;
    box-shadow: 1px 3px 9px #BABCF0;

    font-family: 'Somatic';
    cursor: pointer;
    outline: none;
    text-align: center;
    text-align: center;
    text-decoration: none;
    margin: auto;
    text-align: center;
  }

  .btns2dis {
    background: rgb(117, 117, 117);
    color: #fff;
    border: none;
    font-size: 16px;
    padding: 5px 0;
    border-radius: 50px;
    width: 18vw;
    box-shadow: 1px 3px 9px #BABCF0;

    font-family: 'Somatic';
    cursor: pointer;
    outline: none;
    text-align: center;
    text-align: center;
    text-decoration: none;
    margin: auto;
    text-align: center;
  }
}

.planInfo {
  font-size: 18px;
  margin-top: 15px;
  color: #4F5472;
}